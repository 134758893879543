const messageMap = [
    [1000, 'errors.validation.credentials'],
    [1001, 'errors.userNotFound'],

    [2000, 'errors.validation.email'],
    [2001, 'errors.validation.emailAlreadyExists'],
    [2002, 'errors.userGuidNotFound'],
    [2003, 'errors.userIsResponsibleForLandingPage'],
    [2004, 'errors.userCouldNotBeCreatedInKeycloak'],

    [3000, 'errors.merchantNotFound'],
    [3001, 'errors.merchantGuidNotFound'],
    [3002, 'errors.validation.merchantTitleAlreadyExists'],
    [3003, 'errors.merchantGuidNotDefined'],
    [3004, 'errors.merchantHasAssignedFeeds'],
    [3005, 'errors.validation.merchant.googleMerchantCenterIdAlreadyExists'],

    [4000, 'errors.permissionGroupNotFound'],
    [4001, 'errors.permissionGroupGuidNotFound'],
    [4002, 'errors.permissionGroupHasAssignedUsers'],
    [4003, 'errors.permissionGroupCouldNotBeCreatedInKeycloak'],

    [5000, 'errors.translationLanguageNotDefined'],
    [5001, 'errors.languageNotFound'],
    [5002, 'errors.translationKeyAlreadyExists'],
    [5003, 'errors.translationGuidNotFound'],
    [5004, 'errors.translationNotFound'],
    [5005, 'errors.namespaceNotDefined'],
    [5006, 'errors.namespaceNotFound'],
    [5007, 'errors.defaultLanguageNotFound'],
    [5008, 'errors.languageNotDefined'],

    [6000, 'errors.countryNotFound'],
    [6001, 'errors.countryGuidNotFound'],
    [6002, 'errors.validation.countryNameAlreadyExists'],

    [7000, 'errors.currencyNotFound'],
    [7001, 'errors.currencyGuidNotFound'],
    [7002, 'errors.validation.currencyNameAlreadyExists'],

    [8000, 'errors.languageGuidNotFound'],
    [8001, 'errors.validation.languageCodeAlreadyExists'],
    [8002, 'errors.validation.defaultLanguageMustBeActive'],

    [9000, 'errors.fieldNotFound'],
    [9001, 'errors.fieldGuidNotFound'],
    [9002, 'errors.validation.fieldNameAlreadyExists'],

    [10000, 'errors.validation.feedUrlInvalid'],

    [11000, 'errors.feedNotFound'],
    [11001, 'errors.feedGuidNotFound'],
    [11002, 'errors.validation.feedUrlAlreadyExists'],
    [11003, 'errors.validation.feedUrlNotFound'],
    [11003, 'errors.validation.feedUrlInvalid'],
    [11004, 'errors.validation.feedUrlConnectionInitiateError'],
    [11005, 'errors.validation.feedEquivalentGoogleMerchantCenterFeedExists'],

    [12000, 'errors.uspTextFieldNotFound'],
    [12001, 'errors.uspTextFieldGuidNotFound'],
    [12002, 'errors.validation.tooManyActiveUspTextFieldsForLanguage'],

    [13000, 'errors.topCategoryNotFound'],
    [13001, 'errors.topCategoryGuidNotFound'],
    [13002, 'errors.validation.tooManyTopCategoryFields'],

    [14000, 'errors.feedOperationNotFound'],

    [15000, 'errors.legalPageNotFound'],
    [15001, 'errors.legalPageGuidNotFound'],
    [15002, 'errors.validation.legalPageAlreadyExists'],

    [16000, 'errors.ctaButtonNotFound'],
    [16001, 'errors.ctaButtonGuidNotFound'],
    [16002, 'errors.validation.tooManyActiveCtaButtonsForLocale'],

    [17000, 'errors.landingPageNotFound'],
    [17001, 'errors.landingPageGuidNotFound'],
    [17002, 'errors.landingPageRegionNotFound'],
    [17003, 'errors.landingPageRegionGuidNotFound'],
    [17004, 'errors.validation.landingPageUrlAlreadyExists'],
    [17005, 'errors.validation.landingPageDuplicateRegions'],
    [17006, 'errors.validation.landingPageUrlMustHaveDefaultPage'],
    [17007, 'errors.validation.cannotDeleteDefaultLandingPage'],
    [17009, 'errors.validation.landingPageWrongUrlForMerchant'],

    [19000, 'errors.metadataNotFound'],
    [19001, 'errors.metadataGuidNotFound'],
    [19002, 'errors.metadataGuidNotDefined'],
    [19003, 'errors.validation.metadataAlreadyExistsForLocationKey'],

    [20000, 'errors.homePageNotFound'],
    [20001, 'errors.homePageGuidNotFound'],
    [20002, 'errors.homePageAlreadyExistsForLocationKey'],

    [21000, 'errors.imageInvalid'],

    [22000, 'errors.topMerchantNotFound'],
    [22001, 'errors.topMerchantGuidNotFound'],
    [22002, 'errors.topMerchantAlreadyExistsForLocationKey'],

    [23000, 'errors.topBrandNotFound'],
    [23001, 'errors.topBrandGuidNotFound'],
    [23002, 'errors.topBrandAlreadyExistsForLocationKey'],

    [24000, 'errors.topSellerNotFound'],
    [24001, 'errors.topSellerGuidNotFound'],
    [24002, 'errors.topSellerAlreadyExistsForLocationKey'],

    [26000, 'errors.headerBannerNotFound'],
    [26001, 'errors.headerBannerGuidNotFound'],
    [26002, 'errors.headerBannerAlreadyExistsForLocationKey'],

    [35000, 'errors.googleMerchantServiceFeedNotFound'],
    [35001, 'errors.googleMerchantServiceFeedGuidNotFound'],
    [35002, 'errors.googleMerchantServiceFeedForMerchantAlreadyExists'],
    [35003, 'errors.googleMerchantServiceFeedEquivalentFeedExists'],
]

export const errorMessages = new Map(messageMap);
